<template>
  <div class="content">
    <img src="../../assets/img/404.png">
    <section class="">
      <h1>404</h1>
      <p>抱歉，您访问的页面不存在</p>
      <div>
        <el-button size="mini" type="primary" @click="goFn">返回首页</el-button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'page404',
  data() {
    return {};
  },
  watch: {},
  components: {},
  computed: {},
  methods: {
    goFn() {
      const item = this.$store.state.menuList[0]; let path = null;
      if (item.children && item.children.length > 0) {
        path = item.children[0].functionUrl;
      } else {
        path = item.functionUrl;
      }

      this.$router.push(path);
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
  .content {
    display: flex;
    justify-content: center;

    img {
      height: 400px;
      width: auto;
      margin-right: 100px;
    }

    h1 {
      color: #5895ff;
      font-weight: 500;
      font-size: 120px;
      margin: 0;
    }

    p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 1.6rem;
    }
  }
</style>
