var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("img", { attrs: { src: require("../../assets/img/404.png") } }),
    _c("section", {}, [
      _c("h1", [_vm._v("404")]),
      _c("p", [_vm._v("抱歉，您访问的页面不存在")]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.goFn },
            },
            [_vm._v("返回首页")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }